export type TldrPage = {
  date: string;
  title: string;
  articles?: TldrArticle[];
};

export type TldrArticle = {
  id: string;
  category: string;
  title: string;
  text?: string | string[];
  images?: TldrArticleImage[];
  videos?: TldrArticleVideo[];
  externalArticleURL?: string;
  externalArticleURLTitle?: string;
  date?: string;
};
export type TldrArticleImage = {
  url: string;
  caption?: string;
};
export type TldrArticleVideo = {
  url: string;
  embedContent?: string;
  startTime?: number;
  endTime?: number;
  autplay?: boolean;
  embedAsYoutube?: boolean;
  caption?: string;
  aspectRatio?: number;
};

export const TldrPages: TldrPage[] = [
  {
    date: '21-07-2024',
    title: 'News',
    articles: [
      {
        id: 'geohgiur4s222342ehiuosdfg',
        category: 'tech',
        title: `Samsung Galaxy Ring released`,
        text: [`$400, werkt enkel met Samsung devices`],
        externalArticleURL: 'https://www.youtube.com/watch?v=tYUC_Ni3lb4',
        externalArticleURLTitle: 'Galaxy Ring MKBHD video',
        images: [
          {
            url: 'https://i.redd.it/ta3b2mti0pbd1.png',
            caption: `Galaxy Ring featureset`
          }
        ]
      },
      {
        id: 'geohgiur4sadasd32342ehiuosdfg',
        category: 'tech',
        title: `Crowdstrike blunder: miljoenen windows computers bluescreened in ziekenhuizen, banken, vliegvelden, stockmarkets, ... `,
        text: [`Miljarden $$$ verlies wereldwijd. Enige fix is manueel in safe mode booten en update verwijderen.`],
        videos: [
          {
            url: '/videos/crowdstrike.mp4',
            caption: `Crowdstrike - VRT NWS [1:21]`
          },
          {
            url: '/videos/Crowdstrike Fireship.mp4',
            caption: `BSOD update explained [1:59]`
          }
        ],
        date: '19 juli'
      },
      {
        id: 'geohgiur4sada1233sd32342ehiuosdfg',
        category: 'tech',
        title: `Windows on ARM laptops released`,
        text: [
          'Eindelijk competitor voor macbooks.',
          `Snapdragon chips, battery life vergelijkbaar met macbooks, efficiëntie heel goed vergeleken met Intel en AMD.`
        ],
        videos: [
          {
            url: '/videos/snapdragonlaptop.mp4',
            caption: `Qualcomm laptops LTT- TLDR Cut [1:37]`
          }
        ]
      },
      {
        id: '42ehiuos56465dfg',
        category: 'world',
        title: `Assassination attempt op Donald Trump`,
        text: ['Trump geraakt in oor. Dader dood.'],
        videos: [
          {
            url: '/videos/Trump assassination attempt.mp4',
            caption: `How the Attempted Assassination of Donald Trump Unfolded [1:51]`
          }
        ],
        date: '13 juli'
      },
      {
        id: '42ehiuos522226465dfg',
        category: 'world',
        title: `Biden ends election campaign and endorses Harris as Democratic candidate`,
        images: [
          {
            url: 'https://ichef.bbci.co.uk/ace/standard/1024/cpsprodpb/9f41/live/79f044a0-47a5-11ef-b74c-bb483a802c97.jpg',
            caption: `Biden & Kamala Harris`
          }
        ],
        date: '21 juli'
      },
      {
        id: '42ehiuosdfg',
        category: 'world',
        title: `Trump is a convicted felon`,
        text: ['Guilty on 34 counts of falsifying business records. Trump is officiëel crimineel.'],
        images: [
          {
            url: 'https://www.bu.edu/files/2024/06/crop-AP24109492902749.jpg',
            caption: `Trump in rechtzaal`
          }
        ],
        date: '3 juni'
      },
      {
        id: '42ehiuosd6456fg',
        category: 'world',
        title: `Met QR-code betalen kan binnenkort in hele eurozone`,
        images: [
          {
            url: 'https://images.vrt.be/width1280/2023/11/14/54d09f54-82f9-11ee-91d7-02b7b76bf47f.jpg',
            caption: `QR code betaling`
          }
        ]
      },
      {
        id: '42ehiuos42342dfg',
        category: 'world',
        title: `WikiLeaks-oprichter Julian Assange is vrijgelaten uit de gevangenis`,
        images: [
          {
            url: 'https://d3i6fh83elv35t.cloudfront.net/static/2024/06/2024-06-26T111639Z_165299705_RC2XI8A9B4QM_RTRMADP_3_USA-ASSANGE-AUSTRALIA-1024x683.jpg',
            caption: `Julian Assange met familie in Australië na vrijlating`
          }
        ],
        externalArticleURL: 'https://www.vrt.be/vrtnws/nl/2024/06/25/wikileaks-oprichter-assange-is-vrij-na-deal-met-het-amerikaanse/',
        externalArticleURLTitle: 'VRT NWS artikel',
        date: '25 juni'
      },

      {
        id: '42ehiuos42y6y565y6fff342dfg',
        category: 'belgië',
        title: `Studenten toelatingsexamen geneeskunde krijgen 13 punten extra`,
        text: [
          'De examencommissie stelt vast dat het examen van dit jaar een "hoge moeilijkheidsgraad" had. Daarom zijn er na beraadslaging 13 extra punten toegekend aan alle kandidaten voor beide examenonderdelen.',
          'Van de 4.814 deelnemers aan het toelatingsexamen geneeskunde mogen 1.723 studenten volgend academiejaar beginnen aan de opleiding. Vorig jaar 1600.'
        ],
        images: [
          {
            url: 'https://images.vrt.be/width1280/2024/06/05/d90bdd98-22f5-11ef-8fc9-02b7b76bf47f.jpg',
            caption: `toelatingsexamen geneeskunde`
          }
        ]
      },
      {
        id: '42ehiuos4242dfg',
        category: 'other',
        title: `Japan wil alle research open access maken`,
        text: ['¥10 miljard werd allocated om alle science research uit Japan gratis te maken voor iedereen'],
        images: [
          {
            url: 'https://media.nature.com/lw767/magazine-assets/d41586-024-01493-8/d41586-024-01493-8_27122194.jpg',
            caption: `Scientist doing science stuff`
          }
        ]
      }
    ]
  },
  {
    date: '22-05-2024',
    title: 'News',
    articles: [
      {
        id: 'geohgiur4sadasd32342ehiuo',
        category: 'tech',
        title: `OpenAI Announces New Flagship Model GPT-4o`,
        text: [
          `OpenAI recently announced the latest version of their GPT AI foundation model, GPT-4o. GPT-4o is faster than the previous version of GPT-4 and has improved capabilities in handling speech, vision, and multilingual tasks.`
        ],
        videos: [
          {
            url: 'https://www.youtube.com/embed/gy6qZqHz0EI',
            caption: `GPT4o explainer video`,
            embedAsYoutube: true
          }
        ]
      },
      {
        id: 'geohgiur5454ehiuo',
        category: 'tech',
        title: `ASUS announched de monitor waar we op aan het wachten waren`,
        text: 'Eindelijk WOLED glossy 240Hz 1440p monitor',
        externalArticleURL: 'https://rog.asus.com/monitors/27-to-31-5-inches/rog-strix-oled-xg27aqdmg/',
        externalArticleURLTitle: 'Asus ROG Strix OLED XG27AQDMG',
        videos: [
          {
            url: 'https://www.youtube.com/embed/gMj0E_BNIQU',
            caption: `Asus monitor promo video`,
            embedAsYoutube: true
          }
        ],
        images: [
          {
            url: 'https://mediawebimg.asus.com/files/media/21dad364-dd0e-44e6-b293-f9b5f8f9e391/v1/img/kv/kv_cover.jpg',
            caption: `Asus ROG Strix OLED XG27AQDMG`
          }
        ]
      },
      {
        id: 'geohgiur5454ehiuo',
        category: 'tech',
        title: `TCL demonstrates 4K gaming monitor with a 1,000 Hz refresh rate`,
        text: 'Current highest refresh rate monitor is 540Hz 1080p from ASUS',
        images: [
          {
            url: 'https://i.postimg.cc/0yWCjKjS/firefox-ce-KBwky5q8.png',
            caption: `TCL shows off a 1,000Hz 4K LCD gaming monitor at SID Display Week 2024`
          }
        ]
      },
      {
        id: 'geohgiur432342ehiuo',
        category: 'tech',
        title: `Google unveils Veo, a high-definition AI video generator that may rival Sora`,
        videos: [
          {
            url: 'https://cdn.arstechnica.net/wp-content/uploads/2024/05/veo_cowboy_sun_1.mp4?_=1',
            caption: `Veo demo video 1`
          },
          {
            url: 'https://cdn.arstechnica.net/wp-content/uploads/2024/05/veo_example_012_elephant.mp4?_=3',
            caption: `Veo demo video 2`
          },
          {
            url: 'https://cdn.arstechnica.net/wp-content/uploads/2024/05/veo_example_041_sunflower.mp4?_=2',
            caption: `Veo demo video 3`
          }
        ]
      },
      {
        id: 'geohgiur432342ehiuo',
        category: 'tech',
        title: `Apple announces new accessibility feature: Eye Tracking`,
        videos: [
          {
            url: 'https://www.youtube.com/embed/JpxZSyOLzso',
            caption: `Apple Eye Tracking demo video`,
            embedAsYoutube: true
          }
        ]
      },
      {
        id: 'geohgiur4sadasd32342ehiuo',
        category: 'tech',
        title: `Samsung sold almost 3 billion phones since 2014 `,
        text: [
          `Despite a 10% drop in its market share over the last decade, Samsung still outpaced Apple every year. Samsung's worst year in the past decade was 2022, with 258.2 million smartphones shipped, while Apple shipped 225.3 million that year.`,
          `Samsung's best year was 2015, with the launch of the Galaxy S6 and Galaxy Note 5, reaching 320.9 million shipments compared to Apple's 231.5 million.`
        ],
        images: [
          {
            url: 'https://m-cdn.phonearena.com/images/article/158379-wide-two_1200/Samsung-sold-almost-3-billion-phones-since-2014.webp?1715856402',
            caption: `Samsung phones`
          }
        ]
      },
      {
        id: 'geohgi2233urehiuo',
        category: 'tech',
        title: `Sony wants a stake in the surgical robotics market`,
        text: 'Sony recently announced it has developed a microsurgery assistance robot that is capable of automatic surgical instrument exchange and precision control.',
        videos: [
          {
            url: 'https://www.youtube.com/embed/bgRAkBNFMHk',
            caption: `Sony's microsurgery robot stitching corn`,
            embedAsYoutube: true
          }
        ]
      },
      {
        id: 'geohgi2233urehiuo',
        category: 'gaming',
        title: `Hellblade 2 released, unreal engine 5 capabilities showcased`,
        videos: [
          {
            url: '/videos/hellblade2.mp4',
            caption: `Photo-realistic graphics in Hellblade 2`
          }
        ]
      },
      {
        id: 'gfsdfgsgrergergte4dr3244',
        category: 'WORLD',
        title: `Iranian President Ebrahim Raisi and foreign minister confirmed dead in helicopter crash`,
        text: `Ultraconservative Iranian President Ebrahim Raisi was killed Sunday, along with his foreign minister, in a helicopter crash in Iran's remote northwest, injecting fresh uncertainty as the country's hardline clerical establishment navigates rising regional tensions and domestic discontent.`,
        images: [
          {
            url: 'https://media.cnn.com/api/v1/images/stellar/prod/240520001049-iran-president-helicopter-crash.jpg?c=16x9&q=w_1280,c_fill',
            caption: `Helicopter crash site`
          },
          {
            url: 'https://media.cnn.com/api/v1/images/stellar/prod/gettyimages-1741625441.jpg?q=w_1110,c_fill/f_webp',
            caption: `Iranian President Ebrahim Raisi`
          },
          {
            url: 'https://i.postimg.cc/HLgbVWzH/firefox-2h-Tkk5t35e.png',
            caption: `Crash location`
          }
        ]
      },
      {
        id: 'gfsdfgsgrergergte4dr3244',
        category: 'WORLD',
        title: `Noorwegen, Spanje en Ierland hebben bekendgemaakt dat ze volgende week de Palestijnse Staat officieel zullen erkennen`,
        text: [
          `België heeft de Palestijnse Staat nog niet officieel erkend. Het Belgische standpunt is vandaag wel besproken op het kernkabinet van de federale regering, maar dat stelt de beslissing uit.`,
          'De Israëlische regering roept zijn ambassadeurs uit Noorwegen, Ierland en Spanje terug.'
        ],
        images: [
          {
            url: 'https://i.postimg.cc/X7GkDYYc/firefox-Eaj-GG6e-Qzs.png',
            caption: `International recognition of the state of Palestine`
          }
        ]
      },
      {
        id: 'gfsdfgsgrergergte4dr3244',
        category: 'WORLD',
        title: `Slovak Prime Minister Robert Fico shot in assassination attempt (15 mei)`,
        text: [
          `Slovakia's politics were toxic long before its prime minister was shot`,
          `The assassination attempt on Slovak Prime Minister Robert Fico by a lone gunman has highlighted severe political polarization in Slovakia. The incident has deepened divisions, with both sides of the political spectrum blaming each other for inciting violence.`,
          `The suspect, a 71-year-old pensioner, has been used as a symbol by both liberals and Fico's supporters to further their narratives. `,
          `Slovakia's political climate has become increasingly heated, influenced by historical grievances and continuous political campaigning. Fico's controversial policies and alignment with right-wing nationalism (pro Russia), similar to Hungary's Viktor Orban, have further exacerbated tensions. The incident underscores the country's fragile social and political fabric.`
        ],
        videos: [
          {
            url: 'https://www.youtube.com/embed/QhOc8phbeRI',
            caption: `Slovakian Prime minister assassination attempt video explainer`,
            embedAsYoutube: true
          }
        ]
      },
      {
        id: 'geohgierttertreurehiuo',
        category: 'België',
        title: `Belgium among first in EU to launch digital wallet`,
        text: 'In de loop van 2025 of 2026 moeten we geen rijbewijs of identiteitskaart meer op zak hebben, maar volstaat een de de digitale versie via de overheids applicatie (myGov).',
        images: [
          {
            url: 'https://api.brusselstimes.com/wp-content/uploads/2024/05/e0962fa9-copy-of-featured-images-3-1024x576.png',
            caption: `App / id / bank`
          }
        ]
      },
      {
        id: 'geohgierttertreurehiuo',
        category: 'België',
        title: `Vanaf 7 juli moet elke nieuw verkochte auto in België een waarschuwingsmelding geven als je te snel rijdt`,
        text: 'Op basis van gps-gegevens en camerabeelden kan het systeem opmerken of je sneller dan de toegestane snelheid rijdt en je daar op verschillende manieren op wijzen. ',
        images: [
          {
            url: 'https://images.vrt.be/width1280/2024/05/15/04ee1857-129e-11ef-8fc9-02b7b76bf47f.jpg',
            caption: `App / id / bank`
          }
        ]
      },
      {
        id: 'geohgierttertreurehiuo',
        category: 'België',
        title: `Belgische astronaut Raphaël Liégeois mag in 2026 naar ISS`,
        text: 'Raphaël Liégeois zal in oktober 2026 voor 6 maanden vertrekken naar het ISS. Hij wordt de 3e Belg ooit in de ruimte en nog maar de 2e die naar het ISS gaat',
        images: [
          {
            url: 'https://images.vrt.be/width1280/2024/05/21/0eed4808-1787-11ef-8fc9-02b7b76bf47f.jpg',
            caption: `Raphaël Liégeois`
          }
        ]
      },
      {
        id: 'geohgiureh453ttt43t3iuo',
        category: 'OTHER',
        title: `Blue Origin launches six tourists to the edge of space after nearly two-year hiatus`,
        text: 'Blue Origin`s tourism rocket has launched passengers to the edge of space for the first time in nearly two years, ending a hiatus prompted by a failed uncrewed test flight. ',
        images: [
          {
            url: 'https://media.cnn.com/api/v1/images/stellar/prod/still-20638998-5732332-255-still.jpg?c=16x9&q=h_653,w_1160,c_fill/f_webp',
            caption: ` Blue Origin's NS-25 mission launches carrying six passengers on Sunday, May 19. Blue Origin`
          }
        ]
      },
      {
        id: 'geohgiurehiuo',
        category: 'OTHER',
        title: `UPDATE: Patient Dies Weeks After Kidney Transplant From Genetically Modified Pig`,
        text: 'Richard Slayman received the historic procedure in March. The hospital said it had “no indication” his death was related to the transplant.',
        images: [
          {
            url: 'https://static01.nyt.com/images/2024/05/12/multimedia/12pig-organ-death1-lkgh/12pig-organ-death1-lkgh-superJumbo.jpg?quality=75&auto=webp',
            caption: `Surgeons performing the world’s first kidney transplant from a genetically modified pig into a living human in March`
          }
        ]
      }
    ]
  },
  {
    date: '08-05-2024',
    title: 'News',
    articles: [
      {
        id: '1234',
        category: 'Tech',
        title: `Alle cybertrucks (maar 4000 geproduceerd tot nu toe) zijn recalled door gas pedal malfunction`,
        text: 'Onderdeel bij gaspedaal kan breken/loskomen en vastzitten bij gaspedaal, waardoor de auto 100% gas geeft zonder mogelijkheid tot stoppen. ',
        images: [
          {
            url: 'https://techcrunch.com/wp-content/uploads/2024/04/tesla-cybertruck-1.jpg?w=730&crop=1',
            caption: 'Cybertruck in desert'
          }
        ]
      },
      {
        id: 'W9I9EWPIWGIOPHWLHWEHGJKLWK',
        category: 'Tech',
        title: `Apple event 8 mei: new iPad Pro with M4 + Apple Pencil Pro`,
        text: [
          'OLED coming to iPad Pro: 1,000 nits in everyday use, up to 1,600 nits for HDR. (iPad Pro had microLED tot nu toe)',
          `The new Apple Pencil Pro introduces features closer to what you'd find on pens for dedicated drawing tablets, like a “Barrel Roll” gyroscope feature and a squeeze gesture for switching between software functions`
        ],
        images: [
          {
            url: 'https://www.cnet.com/a/img/resize/7b62ad5a0f195c6951eba275ac82d06287054af6/hub/2024/05/07/6511507b-58c5-41c0-8827-5ce10e46522a/ipad-pro-2024-2.jpg?auto=webp&width=1200',
            caption: 'iPad Pro OLED display with matte finish option (glossy option also available)'
          },
          {
            url: 'https://duet-cdn.vox-cdn.com/thumbor/0x0:3840x2160/750x422/filters:focal(1920x1080:1921x1081):format(webp)/cdn.vox-cdn.com/uploads/chorus_asset/file/25437157/Screenshot_2024_05_07_at_7.35.17_AM.png',
            caption: 'Pencil Pro features'
          }
        ]
      },
      {
        id: 'I8UDFG98UIGYHFDLIGUHF',
        category: 'Tech',
        title: `iPads (iPadOS) toegevoegd aan Digital Markets Act van Europese unie`,
        text: 'Moet anti monopolie aanpassingen maken zoals iPhones (iOS)',
        images: [
          {
            url: 'https://cdn.mos.cms.futurecdn.net/mksRLKqRbUFPSCcH9sCuhS-1200-80.jpg',
            caption: 'iPadOS logo'
          }
        ]
      },
      {
        id: 'IJIDFIOGHJDFGLD',
        category: 'Tech',
        title: `LastPass officially splits from former parent GoTo`,
        text: 'The move follows a string of high-profile attacks on LastPass, prompting the introduction of stricter security measures.',
        images: [
          {
            url: 'https://eu-images.contentstack.com/v3/assets/blt6d90778a997de1cd/blt3a9a15c04dc49a99/64f15c331a256b31a0a353b5/lastpass_II.studio_shutterstock.jpg',
            caption: 'Lastpass'
          }
        ]
      },

      {
        id: 'DFGUIOUIGHFDUIGHDFG',
        category: 'World',
        title: `Bulgarije & Roemenië treden vanaf maart 2024 deels toe tot Schengenzone`,
        text: 'Lucht -en bootverkeer zonder paspoort. Nog niet ter land binnen zonder paspoort',
        images: [
          {
            url: 'https://schengenvisum.info/wp-content/uploads/2023/01/EU-kaart.jpg',
            caption: 'Shengenzone na toetreding'
          }
        ]
      },
      {
        id: 'HRWHGWHEGGJKWGHJKWELHG',
        category: 'World',
        title: `Vanaf april is gebruik en bezit van cannabios legaal in Duitsland`,
        text: 'Volwassenen mogen tot 25gram op zak hebben en 3 plantjes in huis',
        images: [
          {
            url: 'https://i0.wp.com/norml.org/wp-content/uploads/2024/03/germany-cannabis-4.png?fit=730%2C411&ssl=1',
            caption: 'Germany cannabis legalization'
          }
        ]
      },
      {
        id: 'UIFGIUHUIOSFHOUIGHSUFIGHSDFOIGHFUISDF',
        category: 'België',
        title: `Belgium's Princess Elisabeth to attend Harvard University`,
        images: [
          {
            url: 'https://www.reuters.com/resizer/v2/TZS7RDRB6VI75DDUVDPF6PLW2Q.jpg?auth=de912d05d27488e1c3f9c882c6c653cae83c196f9cc1db862ca92c447b02e715&width=640&quality=80',
            caption: 'Princess Elisabeth in Denmark, Oct 15, 2023'
          }
        ]
      },
      {
        id: 'ASDJKASLDKLASKDHSKAJLH',
        category: 'België',
        title: `Flitspalen gaan minder dan 6% marge geven door accuratere technologie.`,
        text: [
          'Vias vraagt de technische marge te doen dalen naar 3 kilometer per uur in zones waar je maximaal 100 kilometer per uur mag en naar 3 procent op snelwegen.',
          'In Frankrijk bedraagt de technische marge 5 kilometer per uur voor snelheden onder de 100 kilometer per uur en in Nederland zelfs maar 3 kilometer per uur.'
        ],
        images: [
          {
            url: 'https://cds.vrt.radio/sites/default/files/styles/article_header_desktop/public/11/article/images/main/2024-04/flits-goed.jpg?h=917ea952&rnd=0cd727c6&itok=f-j7Tora',
            caption: 'Flitspaal'
          }
        ]
      },
      {
        id: 'IOJRIOWHFHUWEIHFIWEUHUFWEIOH',
        category: 'World',
        title: `75 jaar NATO dit jaar`,
        images: [
          {
            url: 'https://img.manoramayearbook.in/content/dam/yearbook/learn/world/images/2024/april/nato-75-a.jpg',
            caption: 'Happy birthday, NATO'
          }
        ]
      },
      {
        id: 'IJOWEFOJEOIFHWEUIEFHWYUEIWYFYUEWI',
        category: 'Other',
        title: `Aorta wordt eigen orgaan, behoorde tot aders (denk ik)`,
        images: [
          {
            url: 'https://cdn.storymd.com/optimized/vAQYQ8h7A8/original.jpg',
            caption: 'Aorta diagram'
          }
        ]
      },
      {
        id: 'VXCNVVJKXCVXKHJLCVXVXCJVKXCJL',
        category: 'Other',
        title: `Japans first privately developed rocket explodes seconds after lift off`,
        text: `Tokyo-based startup Space One failed Wednesday to become Japan's first private firm to put a satellite into orbit after its solid-fuel Kairos rocket burst into flames just seconds after liftoff`,
        images: [
          {
            url: 'https://cdn4.premiumread.com/?url=https://www.japantimes.co.jp/japantimes/uploads/images/2024/03/13/286039.jpg&w=1000&q=100&f=jpg&t=1.2',
            caption: 'Launch and explosion'
          }
        ]
      },
      {
        id: 'JASDKHKLJHSADJLKHALJKDHJLKS',
        category: 'Other',
        title: `A Gene-Edited Pig Kidney Was Just Transplanted Into a Person for the First Time`,
        text: 'In a world first, surgeons in Boston have transplanted a genetically altered pig kidney into a 62-year-old man. Shortly after the kidney was placed in the patient’s body, it started producing urine—a sign that it was functioning as it should',
        images: [
          {
            url: 'https://media.wired.com/photos/65fc59ddcaa60924e64e4207/master/w_1600,c_limit/20240316_mcr_kidney_transplant_028.jpg',
            caption: `Surgery to transplant a gene-edited pig kidney into a human`
          }
        ]
      },
      {
        id: 'XCVJXHCVUIHXCUIVHXCUIOVYHXCIHU',
        category: 'Other',
        title: `O.J. Simpson overleden (aan prostaatkanker)`,
        images: [
          {
            url: 'https://cdn.nos.nl/image/2024/04/12/1071524/1920x1440a.jpg',
            caption: `O.J. Simpson in court trying on murderer's gloves`
          }
        ]
      },
      {
        id: 'SAIOJDASHDPIOUASYDIUASYODA',
        category: 'Other',
        title: `Starship launched`,
        text: [
          'Orbitsnelheid behaald maar niet in orbit geraakt door verkeerde parabool',
          'Eerste keer plasma op beeld, bij space shuttle werd radio communicatie verstoord. Bij starship niet doordat ze niet naar aarde communiceren, maar naar starlink satellieten, dus naar boven.',
          'Booster is met mach 6 in de zee gecrashed.',
          'Fail fast fail forward → er zijn al 4 nieuwe starships klaar om dit jaar te kunnen lanceren'
        ],
        images: [
          {
            url: 'https://i.kinja-img.com/image/upload/c_fit,q_60,w_1600/9250cc9445beef8d2ea5a02364743a93.jpg',
            caption: 'Starship launch'
          },
          {
            url: 'https://static01.nyt.com/images/2024/03/14/multimedia/14starship-launch-plasma-bvkl/14starship-launch-plasma-bvkl-videoSixteenByNineJumbo1600.jpg',
            caption: 'Starship plasma 1'
          },
          {
            url: 'https://i.kinja-img.com/image/upload/c_fit,q_60,w_1600/b76516739cde2bc458592426cae42b4f.jpg',
            caption: 'Starship plasma 2'
          }
        ],
        videos: [
          {
            url: 'https://www.youtube.com/embed/JX1LTw48ymQ',
            caption: 'Starship plasma video [timestamped]',
            embedAsYoutube: true,
            startTime: 3 * 60 + 10
          }
        ]
      },
      {
        id: 'IOJDFOSIJFSIODUYGUIGOSDHGSDGK',
        category: 'Gaming',
        title: `Sea of Thieves niet meer xbox/pc exclusive`,
        text: `released op Playstation 5`,
        images: [
          {
            url: 'https://i.redd.it/ph5qql3dazjc1.jpeg',
            caption: 'SoT op PS5'
          }
        ]
      },
      {
        id: 'SDFIOJSDOOIFUSDIUFYSDUIOYFS',
        category: 'Gaming',
        title: `Steam refund policy changes`,
        text: `refunds op early access games nu ook maar 2h zoals normale games`,
        images: [
          {
            url: 'https://static1.xdaimages.com/wordpress/wp-content/uploads/2024/04/screenshot-2024-04-24-at-6-17-29-pm.png',
            caption: 'Steam refund policy change'
          }
        ]
      },
      {
        id: 'SDPIFSODFUJIOSDFUSDIUFUIFGHK',
        category: 'Gaming',
        title: `Sony Helldivers 2 shenanigans`,
        text: [
          `Sony maakt PSN account verplicht na 2 weken niet enforcen van die regel op steam  voor Helldivers 2 game. PSN account kunnen niet gemaakt worden in deze landen (rood + lichtblauw op foto)`,
          `Dus mensen uit deze landen kunnen plots niet meer spelen.
Helldivers 2 zat opweg om Game of the Year the winnen, maar door die absurde change gebombed door negative reviews.
`,
          `Update: Sony reversed decision door insane community outrage en backlash (voor nu, kan nog steeds later terugkomen natuurlijk)`
        ],
        images: [
          {
            url: 'https://preview.redd.it/list-of-countries-that-cant-make-a-psn-account-v0-zdx0ebreeiyc1.png?width=1080&crop=smart&auto=webp&s=996682201969d13af49df2030be4600d58cd8dfa',
            caption: 'rood + lichtblauw = PSN account creation blocked, donkerblauw = wel mogelijk'
          },
          {
            url: 'https://images.pushsquare.com/849ca26e61633/helldivers-2-may-turn-negative-steam-reviews-into-a-fashionable-in-game-cape-3.large.png',
            caption: 'Hell divers 2 reviews na Sony change'
          },
          {
            url: 'https://i.postimg.cc/gkbP58Ss/steamwebhelper-Dz-SRl-Sirhl.png',
            caption: 'Hell divers 2 reversed decision'
          }
        ]
      }
    ]
  },
  {
    date: '07-03-2024',
    title: 'News',
    articles: [
      {
        id: 'IUJSIFDOUUIFSDYFYSUDFUIYDIYIOUDF',
        category: 'Tech',
        title: `New Nvidia App now in Beta `,
        text: 'Killing GeForce Experience: new overlay, system monitoring, 120fps capture, and lets you add HDR to any game ',
        images: [
          {
            url: 'https://cdn.mos.cms.futurecdn.net/8T3VVTHqVsBaMS956Tp8dm-970-80.jpg.webp',
            caption: 'New Nvidia App interface'
          }
        ]
      },
      {
        id: 'ASOIUDOAIDUOIPASUDIOUASOIPDUCJV',
        category: 'Tech',
        title: `Samsung Galaxy Ring revealed `,
        text: 'Galaxy Ring tracks heart rate, breathing rate, sleep, and night movement, and can even detect early signs of sleep apnea.',
        images: [
          {
            url: 'https://static1.anpoimages.com/wordpress/wp-content/uploads/wm/2024/03/galaxy-ring-android-police-2-1.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5',
            caption: 'Galaxy Ring'
          }
        ]
      },
      {
        id: 'FOSDOPFJSDOIFJSDFOIFJIOSDJ',
        category: 'Tech',
        title: `Apple hit with €1.8 billion EU antitrust fine over music streaming `,
        text: 'Stock drops 80 Billion in value',
        images: [
          {
            url: 'https://www.politico.eu/cdn-cgi/image/width=1024,quality=80,onerror=redirect,format=auto/wp-content/uploads/2024/03/04/GettyImages-1234893723-scaled.jpg',
            caption: 'App store logo'
          }
        ]
      },
      {
        id: 'DFBBDFBDFBDFBDFBDFBDFBFD',
        category: 'Tech',
        title: `Nvidia Hits 2 Trillion Dollar Market Cap `,
        images: [
          {
            url: 'https://en.econostrum.info/wp-content/uploads/2024/02/Nvidia-1-1200x727.png.webp',
            caption: 'Nvidia logo'
          }
        ]
      },
      {
        id: 'ASDSADASDASDQWDQWQDQW',
        category: 'World',
        title: `Greece first Orthodox Christian country to legalise same-sex marriage `,
        images: [
          {
            url: 'https://balkaninsight.com/wp-content/uploads/2023/11/11530741-e1701263996762.jpg',
            caption: 'Pride celebration in Greece'
          }
        ]
      },
      {
        id: 'DFGDFGDFGWEFGEWGWGSDXC',
        category: 'World',
        title: `Trump schuldig aan fraude (eigendommen overschatten voor betere leningen) `,
        text: 'Mag 3 jaar geen bedrijf leiden in New York en kan geen leningen krijgen. Hij moet 355 Miljoen boete betalen.',
        images: [
          {
            url: 'https://i.postimg.cc/G23rvyNQ/GGfdf-Npa8-AAg-R91.jpg',
            caption: 'Trump in court'
          }
        ]
      },
      {
        id: 'GDFGDFASEWFSVDZASCAS',
        category: 'World',
        title: `Hongarije heeft eindelijk lidmaadschap van Zweden in NAVO goedgekeurd `,
        text: 'Nu hebben alle landen het goedgekeurd. Zweden heeft kritiek gegeven in het verleden over ondemocratische ontwikkelingen onder premier Orban. ',
        images: [
          {
            url: 'https://media.npr.org/assets/img/2024/02/26/gettyimages-2024627975_custom-184a47c76e6ccc91645aec52cb5acd2d8a89134c-s1100-c50.jpg',
            caption: 'Zweden en Orban schudden handen'
          }
        ]
      },
      {
        id: 'DFGDFGDFGWEWRTEWRXCFSESDCFSERDEDDDD',
        category: 'World',
        title: `Frankrijk stemt wet voor recht op abortus goed in grondwet. `,
        text: 'Eerste land dat dit doet in grondwet. ',
        images: [
          {
            url: 'https://ichef.bbci.co.uk/news/976/cpsprodpb/13167/production/_132838187_f7367dd30b3c23f58deae774bdcde816435fb5b7.jpg.webp',
            caption: 'Abortuswet celebration Frankrijk'
          }
        ]
      },
      {
        id: 'SDFFE4EREEEEEEE',
        category: 'World',
        title: `Aleksey Navalny gestorven `,
        text: 'Ik voeg het nog toe voor als ge letterlijk niks van nieuws hebt gezien de laatste tijd. ',
        images: [
          {
            url: 'https://beebom.com/wp-content/uploads/2024/02/Nintendo-Direct-Partner-Showcase-to-be-held-this-week.jpg?resize=1200%2C720&quality=75&strip=all',
            caption: 'Nintendo logo'
          }
        ]
      },
      {
        id: 'FDSFDSSDFUIHSDFUIHSDIFHUUISDFHHSFD',
        category: 'Gaming',
        title: `Nintendo Wins Switch Emulator Lawsuit; Yuzu to Shut down and Pay $2.4M `,
        text: 'Yuzu was de grootste emulator voor nintendo games',
        images: [
          {
            url: 'https://www.brookings.edu/wp-content/uploads/2024/02/2024-02-16T162048Z_888745770_RC2R36AV2ZEQ_RTRMADP_3_RUSSIA-NAVALNY-DEATH-1.jpg?quality=75&w=1500',
            caption: 'Navalny herdenking'
          }
        ]
      },
      {
        id: 'IOJSDFOJIFSDOJIFDS',
        category: 'Gaming',
        title: `English no longer top language in Steam `,
        images: [
          {
            url: 'https://cdn.akamai.steamstatic.com/store/home/store_home_share.jpg',
            caption: 'Steam logo'
          }
        ]
      },
      {
        id: 'QWIOPJWPJQDIJIOPQWOPJIWD',
        category: 'belgië',
        title: `Live phone images of fire can now be shared with Brussels emergency centre `,
        videos: [
          {
            url: 'https://www.youtube.com/embed/BwipHzGqRXM',
            embedAsYoutube: true,
            caption: 'Steam logo'
          }
        ]
      },
      {
        id: 'IJUBDOBIUOFDHUIDBF',
        category: 'Other',
        title: `ISS launch: New US-Russian crew heads to space station `,
        videos: [
          {
            url: 'https://www.youtube.com/embed/vXmjhZcTt_I',
            embedAsYoutube: true,
            startTime: 16,
            caption: 'Endeavor launch to ISS'
          }
        ]
      }
    ]
  },
  {
    date: '08-02-2024',
    title: 'News',
    articles: [
      {
        id: 'IOJGUIYODIGOYUFDGYUIGYUIOFD',
        category: 'Tech',
        title: `Wifi 7 released`,
        text: 'Wi-Fi 7 still uses the same three bands as Wi-Fi 6E: 2.4GHz, 5GHz and 6GHz. So why is Wi-Fi 7 such an improvement? It comes down to wider channels. Wi-Fi 7 doubles the channel size of the 6-GHz band, going from 160MHz to 320MHz. ',
        images: [
          {
            url: 'https://www.cnet.com/a/img/resize/a881b9f3f23b15f5e093d787fd01acb99e444cf0/hub/2024/01/09/22ff2f94-9f7e-4771-8a28-aacc00db7598/gettyimages-1314037715.jpg?auto=webp&fit=crop&height=675&width=1200',
            caption: 'Wifi symbol'
          }
        ]
      },
      {
        id: 'IOJUDUIYBDFIYUOFBYUIODFYUIOFBD',
        category: 'Tech',
        title: `RTX 4070 SUPER, 4070 ti SUPER & 4080 SUPER released `,
        text: 'Same same, but different',
        images: [
          {
            url: 'https://www.digitaltrends.com/wp-content/uploads/2024/01/nvidia-rtx-4080-super-ces-2024.jpg?fit=720%2C404&p=1',
            caption: 'RTX 4080 SUPER'
          }
        ]
      },
      {
        id: 'ASOPIUASIOUYUIASOPIDUASUIOD',
        category: 'Tech',
        title: `Facebook celebrates its 20th anniversary `,
        text: 'Same same, but different',
        images: [
          {
            url: 'https://ionnews.mu/wp-content/uploads/2014/02/facebook.jpg',
            caption: 'Happy birthday Facebook'
          }
        ]
      },
      {
        id: 'IOBUICVBYIOCVBIOUCVBYUI',
        category: 'Tech',
        title: `Neuralink implants brain chip in its first human subject `,
        text: `Placed in the part of the brain that plans movements, the device is designed to interpret a person's neural activity, so they can control external devices such as a smartphone or computer with their thoughts`,
        videos: [
          {
            url: 'https://www.youtube.com/embed/z7o39CzHgug',
            embedAsYoutube: true,
            caption: 'Neuralink explainer video'
          }
        ]
      },
      {
        id: 'BIODFUBUIODFYUIBYODFYUIOFB',
        category: 'World',
        title: `Germany to legalize marijuana by April, top official says `,
        text: `Placed in the part of the brain that plans movements, the device is designed to interpret a person's neural activity, so they can control external devices such as a smartphone or computer with their thoughts`,
        images: [
          {
            url: 'https://www.euractiv.com/wp-content/uploads/sites/2/2024/01/shutterstock_1506062354-800x450.jpg',
            caption: 'Cannabis plant'
          }
        ]
      },
      {
        id: 'FBOPIUIUIOPDFUBUIOPDFIOBFD',
        category: 'World',
        title: `Trump must pay $83.3 million in damages for defaming journalist E. Jean Carroll `,
        images: [
          {
            url: 'https://people.com/thmb/kWViOA78hsz3wZfgP344c-fACf0=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2)/e-jean-carroll-donald-trump-court-split-tout-f26b0350288e4b61b0eccdb73cf0d4eb.jpg',
            caption: 'E. Jean Carroll, Donald Trump'
          }
        ]
      },
      {
        id: 'DBIOUFVYUIGUIBIDUSOU',
        category: 'World',
        title: `Mona Lisa: Protesters throw soup at da Vinci painting `,
        videos: [
          {
            url: 'https://www.youtube.com/embed/5YuPuGIbhP8',
            embedAsYoutube: true,
            caption: 'Protesters throw soup at Mona Lisa'
          }
        ]
      },
      {
        id: 'OPIGRPBDOUDISFJ',
        category: 'Gaming',
        title: `Eerste major update voor CS2 `,
        videos: [
          {
            url: 'https://www.youtube.com/embed/ZBGno72Y-fI',
            embedAsYoutube: true,
            caption: '3kliksphilip video'
          }
        ]
      },
      {
        id: '234TESGFAa',
        category: 'belgië',
        title: `Politieke debatten over abortuswet versoepelen van 12 weken naar 18 weken `,
        images: [
          {
            url: 'https://i.guim.co.uk/img/media/531e63a7d5bada3da98699082049e2b6b7642626/202_493_2566_1877/master/2566.jpg?width=1900&dpr=1&s=none',
            caption: 'Baby op 18 weken'
          }
        ]
      },
      {
        id: 'DSFSDFF223WESD',
        category: 'belgië',
        title: `Vanaf de zomer komt elke fiets in nationaal fietsregister om diefstal tegen te gaan (waarschijnlijk elke nieuw verkochte fiets + vrijwillig huidige fiets, verwacht ik)`,
        images: [
          {
            url: 'https://static.nieuwsblad.be/Assets/Images_Upload/2023/01/25/9fc8e322-cd9a-4b82-87aa-9da6f4b81fb6.jpg',
            caption: 'Diefstal fiets'
          }
        ]
      },
      {
        id: 'FESRRDFVEASWAESD',
        category: 'Other',
        title: `Lewis Hamilton gaat weg van Mercedes en tekent bij Ferrari in 2025 `,
        images: [
          {
            url: 'https://e0.365dm.com/24/02/768x432/skysports-lewis-hamilton-ferrari_6440225.jpg?20240201105031',
            caption: 'Lewis Hamilton'
          }
        ]
      },
      {
        id: 'EWFWEFWE',
        category: 'Other',
        title: `NASA's historic Mars helicopter Ingenuity grounded for good after 72 flights `,
        text: [
          `The fate of Ingenuity was sealed when imagery beamed back to Earth after its 72nd and final flight on Jan. 18 showed that a portion of one of the miniature whirligig's twin rotor blades had broken off, leaving it incapable of further operation, NASA officials said.`,
          `What was planned as a 30-day technology demonstration of no more than five short flights ended up stretching well beyond the expectations of engineers who designed and built the helicopter.`,
          `Ingenuity ultimately buzzed over the Martian terrain 14 times farther than originally planned, logging more than two hours, eight minutes of flight time and covering a distance of 10.5 miles (17 km) through all 72 flights`
        ],
        images: [
          {
            url: 'https://i0.wp.com/www.sciencenews.org/wp-content/uploads/2024/01/012524_am_marsingenuity_feat.jpg',
            caption: 'Ingenuity helicopter'
          }
        ]
      }
    ]
  },
  {
    date: '24-01-2024',
    title: 'News',
    articles: [
      {
        id: 'ASDASDD',
        category: 'Tech',
        title: `Samsung Galaxy S24 released`,
        videos: [
          {
            url: '/videos/galaxy_s24.mp4',
            caption: 'Galaxy s24 recap'
          }
        ]
      },
      {
        id: 'EEEE',
        category: 'Tech',
        title: `Framework 16 laptop heeft easy upgradable gpu `,
        videos: [
          {
            url: 'https://www.youtube.com/embed/eUCm4wKarpQ',
            embedAsYoutube: true,
            caption: 'Framework 16 LTT review'
          }
        ]
      },
      {
        id: 'CCCC',
        category: 'World',
        title: `Saudi Arabia allows first alcohol sales — but only to foreign diplomats `,
        images: [
          {
            url: 'https://www.ft.com/__origami/service/image/v2/images/raw/ftcms%3A114c01c6-1c35-42d4-a9e6-1d52469a3554?source=next-article&fit=scale-down&quality=highest&width=700&dpr=1',
            caption: 'Bottles of alcohol'
          }
        ]
      },
      {
        id: 'CFSDSDFSDFSDCCC',
        category: 'Gaming',
        title: `Palworld (Pokemon met guns) becomes an overnight sensation `,
        text: 'Japanese developer Pocketpair hit an unexpected home run with its game “Palworld,” selling 7 million units in less than a week on the PC Steam service alone ',
        videos: [
          {
            url: 'https://www.youtube.com/embed/IltMIJeK-1M?start=50;',
            embedAsYoutube: true,
            caption: 'Palworld trailer'
          }
        ]
      },
      {
        id: 'CFSDSDFSDFGFDGDFGFSDCCC',
        category: 'België',
        title: `Thousands of e-scooters to be removed from Brussels streets next week. The number of shared e-scooters will fall from 21,000 to 8,000 by Thursday 1 February. `,
        images: [
          {
            url: 'https://api.brusselstimes.com/wp-content/uploads/2021/10/is41onim4101721nn.jpg',
            caption: 'Brussels e-scooters'
          }
        ]
      },
      {
        id: 'CFSDSDFSDFSASAGFDGDFGFSDCCC',
        category: 'Other',
        title: `Spirit en Opportunity rovers op Mars vieren 20ste verjaardag `,
        text: `Twenty years ago this month, twin spacecraft made dramatic planetary landings and set about creating an unrivaled legacy of science and exploration. NASA's Spirit and Opportunity rovers were supposed to operate on the surface of Mars for just three months. But both solar-powered robots far outlived their warranties, setting travel and longevity records`,
        images: [
          {
            url: 'https://cdn.mos.cms.futurecdn.net/3hgec7iPZccY9eiZWTUCdd-650-80.jpg.webp',
            caption: 'Mars rover'
          }
        ]
      }
    ]
  },
  {
    date: '14-01-2024',
    title: 'News',
    articles: [
      {
        id: '3W4FDCSAWSD',
        category: 'Tech',
        title: `Nvidia and Convai are bringing generative AI NPCs to video games `,
        videos: [
          {
            url: 'https://www.youtube.com/embed/psrXGPh80UM?start=06;',
            embedAsYoutube: true,
            caption: 'AI NPCs Demo'
          }
        ]
      },
      {
        id: '3W4FDSSSCSAWSD',
        category: 'Tech',
        title: `Apple Vision Pro AR/VR headset launches on February 2nd `,
        videos: [
          {
            url: 'https://www.youtube.com/embed/TX9qSaGXFyg',
            embedAsYoutube: true,
            caption: 'Introducing Apple Vision Pro'
          }
        ]
      },
      {
        id: '3W4FDSSSCFFFSAWSD',
        category: 'World',
        title: `US and UK launch multiple airstrikes on Houthi targets in Yemen `,
        text: 'Coalition forces struck over 60 targets in Yemen against Houthi forces after the Iran-backed group attacked commercial shipping in the Red Sea. ',
        images: [
          {
            url: 'https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iNBSt.l1z1g0/v3/620x-1.jpg',
            caption: 'Jet fighter taking off to Yemen'
          }
        ]
      },
      {
        id: '3W4FDSSSCWWWFFFSAWSD',
        category: 'Gaming',
        title: `Next Battlefield Aims To Have "The Most Realistic" Destruction In A Video Game `,
        images: [
          {
            url: 'https://static1.thegamerimages.com/wordpress/wp-content/uploads/2022/11/Battlefield2042Season3.jpg?q=50&fit=contain&w=1140&h=570&dpr=1.5',
            caption: 'Battlefield 2042'
          }
        ]
      },
      {
        id: '3W4FDSSSCWDDDWWFFFSAWSD',
        category: 'België',
        title: `Premier Alexander De Croo in China voor tweedaags bezoek. De toekomst van Taiwan en Chinese spionagepraktijken worden besproken. `,
        images: [
          {
            url: 'https://images0.persgroep.net/rcs/tbnbGYRISHZEf86Y30ri8JuNBaU/diocontent/239285698/_fitwidth/694/?appId=21791a8992982cd8da851550a453bd7f&quality=0.8&desiredformat=webp',
            caption: 'Premier De Croo handenschuddend met Chinese president Xi Jinping'
          }
        ]
      },
      {
        id: '3W4FDSSSCWDFDFFDFDDWWFFFSAWSD',
        category: 'Other',
        title: `Boeing stock crash na deur blowout op nieuwe 737 Max `,
        images: [
          {
            url: 'https://i.postimg.cc/nV5ZpNdH/boeing.jpg',
            caption: 'Stock crash'
          },
          {
            url: 'https://cloudfront-us-east-2.images.arcpublishing.com/reuters/E6TI2AKYZNJLNMTLPEES5BVIBE.jpg',
            caption: 'Ripped door'
          }
        ]
      }
    ]
  },
  {
    date: '07-01-2024',
    title: 'News',
    articles: [
      {
        id: '3W4FDSSS4343CWDFDASDASDFFDFDDWWFFFSAWSD',
        category: 'Tech',
        title: `LG's display division showed off a 27-inch OLED display panel with a 480Hz refresh rate ahead of CES 2024 `,
        images: [
          {
            url: 'https://www.cnet.com/a/img/resize/4048dcf0948fbbd2c77b8178b255065ab5ea7a73/hub/2023/04/06/85baadae-5027-4b77-8ccb-678e60f59a4d/lg-ultragear-oled-27-gaming-monitor-3733.jpg?auto=webp&fit=crop&height=675&width=1200',
            caption: 'LG OLED display'
          }
        ]
      },
      {
        id: 'IOJSDFO32132JSDFIOJSDF',
        category: 'Tech',
        title: `VESA introduces Adaptive-Sync 1.1a standard with new Dual-Mode for gaming monitors.`,
        text: `Adaptive-Sync Display version 1.1a provides updated testing procedures and logo support for an emerging category of displays that can operate at different maximum refresh rates when resolution is reduced. This optional "Dual Mode" testing and logo support allows display OEMs with qualifying hardware to certify their products at two different sets of resolution and refresh rate (for example, 4K/144Hz and 1080p/280Hz). `,
        images: [
          {
            url: 'https://cdn.videocardz.com/1/2024/01/ASUS-DUAL-MODE-HERO-1200x624.jpg',
            caption: 'Asus Dual Mode Display'
          }
        ]
      },
      {
        id: 'IOJSD2112OJSDFISSSOJSDF',
        category: 'Tech',
        title: `SpaceX launches first set of satellites with direct-to-cell capabilities image`,
        images: [
          {
            url: 'https://www.t-mobile.com/news/_admin/uploads/2023/12/5043941_Newsroom-Coffee-Jaguar-Assets-REVW-02-ntc-1-1-22.png',
            caption: 'SpaceX / T-Mobile'
          }
        ]
      },
      {
        id: 'IOJSDFOJSAAAWEFWFEDFISSSOJSDF',
        category: 'Gaming',
        title: `Modded Path-Traced Cyberpunk 2077 Looks Utterly Insane `,
        videos: [
          {
            url: '/videos/cyberpunk2077.mp4',
            caption: 'Cyberpunk 2077 with realism mods',
            autplay: true
          }
        ]
      },
      {
        id: 'IOJSDFOJSAAADXVVCXDDDFISSSOJSDF',
        category: 'Gaming',
        title: `14,500 games released on steam in 2023 `,
        images: [
          {
            url: 'https://www.destructoid.com/wp-content/uploads/2024/01/steam.jpg',
            caption: 'Steam games'
          }
        ]
      },
      {
        id: 'IOJSDFOJSAAADVERVERRFFFDDDFISSSOJSDF',
        category: 'Other',
        title: `World's first tunnel to a magma chamber could unleash unlimited energy`,
        text: 'In Iceland, scientists are planning to drill two boreholes to a reservoir of liquid rock. One will give us our first direct measurements of magma, the other could supercharge geothermal power',
        images: [
          {
            url: 'https://images.newscientist.com/wp-content/uploads/2023/12/28121726/SEI_184926611.jpg?width=900',
            caption: 'Magma chamber illustration'
          }
        ]
      }
    ]
  },
  {
    date: '17-12-2023',
    title: 'News',
    articles: [
      {
        id: 'U38UR89UWRWEEVERE89UF',
        category: 'Tech',
        title: 'Google nieuwe LLM (large language model) Gemini onthuld',
        text: 'Gemini is built for multimodality that reasons seamlessly across text, images, video, audio, and code. Gemini AI, according to Google, outperforms ChatGPT on over 30 out of 32( around 90%) academic benchmarks.'
      },
      {
        id: 'U38UR89VDDVVUWE8SS9UF',
        category: 'Tech',
        title: 'Epic Games wint lawsuit tegen Google',
        text: 'Jury decides Google has illegal monopoly in app store fight. In januari volgende datum om te zien wat er gaat moeten veranderen.'
      },
      {
        id: 'U38URFEEF22E2E89UDDWE8SS9UF',
        category: 'Tech',
        title: 'Samsung Unveils 27″ QHD QD-OLED Gaming Monitors With 360Hz Refresh Rate'
      },
      {
        id: 'U38UR89UASDDASFASDDDWE8SS9UF',
        category: 'Tech',
        title: 'NVIDIA GeForce RTX 40 SUPER launch scheduled for January'
      },
      {
        id: 'U38UR89UDDWEEFWWEF8SS9UF',
        category: 'World',
        title: 'Putin stelt zichzelf opnieuw kandidaat als opvolger van zichzelf, nieuwe ambtstermijn loopt tot 2030'
      },
      {
        id: 'U38UR89UDDWF23F23FE8SS9UF',
        category: 'Gaming',
        title: 'The Finals game released',
        text: 'Nieuwe hot upcoming free to play fps met volledig destructible environment',
        videos: [
          {
            url: 'https://www.youtube.com/embed/Tk8TF0Gs9bg?start=43&end=65;',
            embedAsYoutube: true,
            caption: 'The Finals Desctruction'
          }
        ]
      },
      {
        id: 'U38UR89UREERFERFDDWE8SS9UF',
        category: 'Gaming',
        title: "Baldur's Gate 3 game of the year gewonnen",
        text: 'Gemaakt door Larian Studios, Belgisch bedrijf uit Gent'
      },
      {
        id: 'U38UR89UDDWE8SVFVFS9UF',
        category: 'Gaming',
        title: 'Avatar: Frontier of Pandora released door Ubisoft'
      },
      {
        id: 'U38UR89UDDWE8CSDSCDSS9UF',
        category: 'België',
        title: 'Veel stakingen van openbaar vervoer (treinen, bussen)'
      },
      {
        id: 'U38UR89UD22D1D12DDWE8SS9UF',
        category: 'België',
        title: 'Premier van België tussen 1992-1996, Jean Luc Dehaene',
        text: 'Ondertussen al bijna 10 jaar overleden, gebruikt door politieke partij CD&V in Ai generated filmpje voor campagne',
        videos: [
          {
            url: 'https://www.youtube.com/embed/gRfPtzuDksU?start=0&end=10;',
            caption: 'Respect werkt volgens Jean-Luc Dehaene',
            embedAsYoutube: true
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SD32D23S9UF',
        category: 'België',
        title: 'Koning Filip en Koningin Mathilde zijn 3 dagen op staatsbezoek naar Duitsland geweest voor economische samenwerking'
      },
      {
        id: 'U38UR89UDDWE8SSF4F49UF',
        category: 'Other',
        title: 'Tesla recalls more than 2 million cars over autopilot safety concerns'
      }
    ]
  },
  {
    date: '24-12-2023',
    title: 'News',
    articles: [
      {
        id: 'U38UR89UDDWE8VDFVWESS9UF',
        title: 'Google settled voor 700 miljoen (minder dan wat playstore verdient per maand) ',
        category: 'Tech',
        images: [
          {
            url: 'https://ca-times.brightspotcdn.com/dims4/default/effda04/2147483647/strip/true/crop/4127x2751+0+0/resize/1200x800!/format/webp/quality/75/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F92%2Ff9%2F09b9800c410de4b7b7121ed30292%2F9c560cd6682f4859a2e3770e5621cdbc',
            caption: 'Google'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SS9UFXCVXCV',
        title: 'Fans met built in schermen zijn released ',
        category: 'Tech',
        images: [
          {
            url: 'https://i.postimg.cc/s2B4dQ1f/firefox-w-Dy-ESmiix9.png',
            caption: 'Fans met built-in schermen'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SADSZSS9UF',
        title: 'Aanslag op universiteit door 24-jarige student in Praag, Tsjechië, 14 dood en 25 gewond',
        category: 'World',
        images: [
          {
            url: 'https://images.lbc.co.uk/images/623950?crop=16_9&width=660&relax=1&format=webp&signature=2evhIUHqQGTECYbtFBMn-n6_XUw=',
            caption: 'Universiteit Praag'
          }
        ]
      },
      {
        id: 'U38UR89UDDWDWQDQWE8SS9UF',
        title: 'Vulkaanuitbarsting in IJsland (inwoners werden vorige maand al geëvacueerd)',
        category: 'World',
        images: [
          {
            url: 'https://images.vrt.be/vrtnws_web/2023/12/19/aa36daf1-9e2e-11ee-b483-02b7b76bf47f.jpg?width=800&height=450',
            caption: 'Vulkaanuitbarsting IJsland'
          }
        ]
      },
      {
        id: 'U38URQQWDQW89UDDWE8SDFVDFVDFS9UF',
        title: `Oppositie kandidaat van Putin mag niet meedoen aan verkiezingen omdat ze kritische uitspraken deed over het beleid van Putin. Kiescommisie annuleert haar kandidatuur omdat ze 'plots' meer dan 100 fouten vinden in de documenten voor haar kandidatuur.`,
        category: 'World',
        images: [
          {
            url: 'https://www.journalofdemocracy.org/wp-content/uploads/2023/02/McFaul-photo.jpg',
            caption: 'Putin'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SSVDFDF9UF',
        title: `Steam winter sale is live`,
        category: 'Gaming',
        images: [
          {
            url: 'https://s.yimg.com/ny/api/res/1.2/7ZQyyPnpBkxbi7tKO6UgcA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTM4NA--/https://s.yimg.com/os/creatr-uploaded-images/2023-12/2e4341c0-a033-11ee-9fdf-3378f6a751a0',
            caption: 'Winter Sale Live'
          }
        ]
      },
      {
        id: 'U38URQDWDQW89UDDWE8SS9UF',
        title: `Sony krijgt patent voor dynamische difficulty curve in games`,
        category: 'Gaming',
        images: [
          {
            url: 'https://i.extremetech.com/imagery/content-types/05YulKtiu3gY8GCKMWVUA5S/images-1.fill.size_630x750.v1702919463.png',
            caption: 'Filed patent'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SDDFSFSDFS9UF',
        title: `Princess Elisabeth pays first official visit to Ghent children's hospital `,
        category: 'België',
        images: [
          {
            url: 'https://media.gettyimages.com/id/1869619627/photo/her-royal-highness-princess-elisabeth-of-belgium-duchess-of-brabant-visits-the-princess.jpg?s=1024x1024&w=gi&k=20&c=gx-p6zc1icLRcuo5V_P_RSYWiHphYo0UyAVF9uYdv5E=',
            caption: 'Elisabeth in Ghent'
          }
        ]
      },
      {
        id: 'U38UR89UDDWEEQWWQDAWQ8SS9UF',
        title: `Kerstboom omgevallen op de kerstmarkt van Oudenaarde, 1 vrouw overleden `,
        category: 'België',
        images: [
          {
            url: 'https://static.nieuwsblad.be/Assets/Images_Upload/2023/12/22/77b11b53-377e-4d0c-b026-c8fa9ade34ff.png',
            caption: 'Omgevallen kertboom'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8QQQSS9UF',
        title: `De warmste week (goed doel door radio MNM & Studio Brussel) is voorbij, 9 miljoen euro opgehaald voor goede doelen`,
        category: 'België',
        images: [
          {
            url: 'https://dewarmsteweek.be/shared/assets/logos/de_warmste_week.png',
            caption: 'Warmste week logo'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SFFFS9UF',
        title: `Gemeente Begijnendijk in Vlaams-Brabant, schrapt voorrang van rechts in heel de gemeente en plaatst verkeersborden aan kruispunten om dit toe te passen`,
        category: 'België'
      },
      {
        id: 'U38UWWWWWR89UDDWE8SS9UF',
        title: `A day after the pope's announcement that Catholic priests may bless same-sex couples, one New York couple receives theirs.`,
        category: 'Other',
        images: [
          {
            url: 'https://static01.nyt.com/images/2023/12/19/us/19NAT-GAY-CATHOLIC-1/19NAT-GAY-CATHOLIC-1-superJumbo.jpg',
            caption: 'priest blessing same-sex marriage'
          }
        ]
      }
    ]
  },
  {
    date: '31-12-2023',
    title: 'News',
    articles: [
      {
        id: 'U38UR89UDDWE8SSVVV9UF',
        title: 'Nvidia Hits $1 Trillion Market Value',
        category: 'Tech',
        images: [
          {
            url: 'https://www.visualcapitalist.com/wp-content/uploads/2023/05/nvidia-1-trillion-market-cap-club-MAIN.jpg',
            caption: 'Nvidia in 1 trillion market cap club'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SSZZZ9UF',
        title: 'South Africa files genocide case against Israel at World Court for its crackdown against the Palestinian group Hamas in Gaza',
        category: 'World',
        images: [
          {
            url: 'https://euromaidanpress.com/wp-content/uploads/2022/03/word-image-87.png',
            caption: 'World Court'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8XXXSS9UF',
        title: 'Koningin Marghrete II (83 jaar oud) van Denemarken neemt afstand van de troon. Ze zit al op de troon sinds 1972.',
        category: 'World',
        images: [
          {
            url: 'https://www.aljazeera.com/wp-content/uploads/2023/12/2023-12-31T173559Z_1573361278_RC24KM9UOIHU_RTRMADP_3_DENMARK-ROYALS-QUEEN-1704046319.jpg?resize=770%2C513&quality=80',
            caption: 'Konigin Marghrete II'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SS9CCCUF',
        title: `Romania and Bulgaria have reached an agreement with Austria to join Europe's open-borders Schengen area by air and sea from March 2024`,
        category: 'World',
        images: [
          {
            url: 'https://www.bgnesnews.com/storage/images/2023/12/28/1376/640X360-IMG_7875.jpeg',
            caption: 'Romania and Bulgaria shaking hands with Austria'
          }
        ]
      },
      {
        id: 'U38UR89UDDWE8SS9USSSF',
        title: `Tencent Drops $46 Billion in market cap after China proposes online-gaming changes`,
        text: [
          'China has proposed new rules for online gaming that aim to restrict purchases and limit compulsive playing behaviours. The new rules ban companies from offering incentives to people who play video games or make in-game purchases each day. Other restrictions include limits on how often players can add money to online money accounts, called wallets.',
          'Chinese gamers are also currently required to provide identification when registering to play online under existing rules.'
        ],
        category: 'Gaming',
        images: [
          {
            url: 'https://i.postimg.cc/4d97wpyt/firefox-PQvlt-Vn-KQQ.png',
            caption: 'Romania and Bulgaria shaking hands with Austria'
          }
        ]
      },
      {
        id: 'U38URDDDD89UDDWE8SS9UF',
        title: `Reports have surfaced claiming that GTA 5's full source code, of which snippets were previously seen, has now leaked in full `,
        category: 'Gaming',
        images: [
          {
            url: 'https://i.ytimg.com/vi/MiNtlnsqLfA/maxresdefault.jpg',
            caption: 'GTA 5 source code leaked'
          }
        ]
      },
      {
        id: 'U38UR89UDFFFDWE8SS9UF',
        title: 'Microsoft quietly launches free dedicated Copilot app → GPT-4 bruikbaar zonder microsoft edge te moeten gebruiken',
        category: 'Tech',
        images: [
          {
            url: 'https://cdn.neowin.com/news/images/uploaded/2023/12/1703899592_copilot_ios_ico.jpg',
            caption: 'Microsoft Copilot'
          }
        ]
      },
      {
        id: 'U38UR89UAAADDWE8SS9UF',
        title: `Amazon's Prime Video to start showing ads starting Feb. 5`,
        category: 'Other',
        images: [
          {
            url: 'https://i.cbc.ca/1.7070714.1703809288!/fileImage/httpImage/image.JPG_gen/derivatives/16x9_940/amazon-india.JPG',
            caption: 'Prime video'
          }
        ]
      },
      {
        id: 'U38UR89UDSSSDWE8SS9UF',
        title: 'Vanaf 1 januari mag gft afval niet meer in restafval vuilbak ',
        category: 'België',
        images: [
          {
            url: 'https://static.nieuwsblad.be/Assets/Images_Upload/2023/12/28/f285f844-460f-437d-801e-78a6bdcc43a3.png',
            caption: 'GFT wel niet'
          }
        ]
      }
    ]
  }
];
