import { createTheme } from '@mui/material';
import { blue, green, grey, red, yellow } from '@mui/material/colors';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'uppercase',
          fontFamily: 'Quicksand',
          fontWeight: 'bolder'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: grey[400],
          fontFamily: 'Roboto'
        },
        h3: {
          fontSize: '1.2rem',
          fontFamily: 'Quicksand',
          fontWeight: 500
        },
        h4: {
          fontSize: '1.6rem',
          fontFamily: 'Quicksand',
          fontWeight: 500
        },
        h5: {
          fontSize: '1.3rem',
          fontFamily: 'Quicksand',
          fontWeight: 700
        },
        h6: {
          fontSize: '1.2rem',
          fontFamily: 'Quicksand',
          fontWeight: 600
        },
        caption: {
          fontFamily: 'Quicksand',
          fontSize: '0.9rem',
          color: grey[500],
          fontWeight: 500
        }
      }
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true
      }
    }
  },
  palette: {
    primary: {
      main: red[800],
      light: red[400],
      dark: red[800]
    },
    secondary: {
      main: blue[800],
      light: blue[400],
      dark: blue[800]
    },
    success: {
      main: green[800],
      light: green[400],
      dark: green[800]
    },
    error: {
      main: red['A700'],
      light: red[900],
      dark: red['A700']
    },
    warning: {
      main: yellow[800],
      light: yellow[400],
      dark: yellow[800]
    },
    info: {
      main: grey[600],
      light: grey[300],
      dark: grey[800]
    }
  }
});
