export const useScrollToIdInCenter = () => {
  return (targetId: string) => {
    const element = document.getElementById(targetId);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.scrollY;
      const middle = absoluteElementTop - window.innerHeight / 4;
      window.scrollTo({ top: middle, behavior: 'smooth' });
    }
  };
};
