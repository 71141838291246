import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AppLayout } from './AppLayout';
import { ErrorPage } from './pages/ErrorPage';
import { HomePage } from './pages/HomePage';
import { AppRoutesEnum } from './util/enum/AppRoutesEnum';

export const Routing = () => {
  const routes: RouteObject[] = [
    {
      element: <HomePage />,
      path: AppRoutesEnum.HOME
    },
    {
      element: <HomePage />,
      path: AppRoutesEnum.TLDR_DATE
    },
    {
      element: <ErrorPage />,
      path: AppRoutesEnum.ERROR
    }
  ];
  const createRoute = (props: RouteObject): RouteObject => {
    return {
      path: props.path,
      errorElement: <Navigate to={AppRoutesEnum.ERROR} />,
      element: <AppLayout showNav={true}>{props.element}</AppLayout>
    };
  };
  const router = createBrowserRouter([...(routes?.map((routeProps) => createRoute(routeProps as RouteObject)) ?? [])]);
  return <RouterProvider router={router} />;
};
