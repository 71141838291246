// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBRiGsb2kJC5EK-2933m0xSaixlKxyHVDw',
  authDomain: 'tldr-98fe9.firebaseapp.com',
  projectId: 'tldr-98fe9',
  storageBucket: 'tldr-98fe9.appspot.com',
  messagingSenderId: '136191203136',
  appId: '1:136191203136:web:ac24daea342ca6d984cca5'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);