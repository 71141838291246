import { Box, Container } from '@mui/material';
import { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { Nav } from './components/Nav';

export type AppLayoutProps = PropsWithChildren<{
  showNav?: boolean;
}>;

const AppBackground = styled(Box)`
  background-image: url('/images/background.jpg');
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

export const AppLayout = ({ showNav, children }: AppLayoutProps): JSX.Element => {
  return (
    <>
      <AppBackground />
      {showNav && <Nav />}
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mx: 'auto',
          borderRadius: 3,
          boxShadow: 'none',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            mt: showNav ? '5rem' : 0,
            px: { xs: 3, sm: 4, md: 5 },
            py: 3,
            '::before': {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              // filter: 'drop-shadow(0 0 1.6em rgba(0, 0, 0, 0.9))',
              content: '""',
              position: 'absolute',
              // inset: '5.6rem 0',
              borderRadius: 4,
              zIndex: -1
            }
          }}
        >
          {children}
        </Box>
      </Container>
    </>
  );
};
