import { Box, Button, Divider, ListItemIcon, Menu, MenuItem, Stack, Typography, Avatar } from '@mui/material';
import {
  ArrowBackIosTwoTone,
  ArrowForwardIosTwoTone,
  FormatItalicTwoTone,
  LoginTwoTone,
  Logout,
  NewspaperTwoTone,
  Settings
} from '@mui/icons-material';
import { AppRoutesEnum } from '../util/enum/AppRoutesEnum';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TldrPages } from '../data/articles';
import { grey } from '@mui/material/colors';
import { useResponsiveScreen } from '../util/hooks/useResponsiveScreen';
import { useScrollToIdInCenter } from '../util/hooks/useScrollToIdInCenter';
import { useParseDate } from '../util/hooks/useParseDate';
import { auth, provider } from '../config/firebase';
import { signInWithPopup, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Loader } from './Loader';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';

export const Nav = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, loading, error] = useAuthState(auth);
  const categoryElements = document.querySelectorAll('.category');
  const navLinks = document.querySelectorAll('.navlink');

  function handleScroll() {
    let current = '';
    const scrollOffset = window.innerHeight / 4;

    categoryElements?.forEach((category: Element) => {
      const categoryTop = category.getBoundingClientRect().top + window.scrollY;
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      if (scrollPosition >= categoryTop - scrollOffset - 20) {
        current = category.id;
      }
    });

    navLinks.forEach((navlink) => {
      navlink.classList.remove('active');
      if (navlink.textContent?.toLocaleLowerCase() === current.toLocaleLowerCase()) {
        navlink.classList.add('active');
      }
    });
  }

  const debouncedHandleScroll = debounce(handleScroll, 100); // Adjust the delay as needed
  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [categoryElements, navLinks,debouncedHandleScroll]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { xs, sm, md, lg, xl } = useResponsiveScreen();

  const parseDate = useParseDate();

  const tldrDatesSorted = TldrPages.map((page) => page.date).sort((a, b) => parseDate(b).getTime() - parseDate(a).getTime());
  const currentPath = useLocation().pathname;
  const splitPath = currentPath.split('/');
  const dateFromPath = splitPath[splitPath.length - 1];
  const date = dateFromPath?.length > 0 ? parseDate(dateFromPath) : parseDate(tldrDatesSorted[0]);
  const dateString = dateFromPath?.length > 0 ? dateFromPath : tldrDatesSorted[0];
  const nagivate = useNavigate();

  const page = TldrPages.find((page) => page.date === dateString);
  const categories = page?.articles
    ?.map((article) => article.category)
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index);

  const [anchorElDates, setAnchorElDates] = useState<null | HTMLElement>(null);
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const openDates = Boolean(anchorElDates);
  const openMenu = Boolean(anchorElMenu);
  const handleClickDates = (event: React.MouseEvent<Element, MouseEvent>) => {
    setAnchorElDates(event.target as HTMLElement);
  };
  const handleCloseDates = () => {
    setAnchorElDates(null);
  };
  const handleClickMenu = (event: React.MouseEvent<Element, MouseEvent>) => {
    setAnchorElMenu(event.target as HTMLElement);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };
  const findNextDate = (date: Date): string | undefined => {
    const nextDates = tldrDatesSorted.filter((tldrDate) => parseDate(tldrDate).getTime() > date.getTime());
    const sortedNextDates = nextDates.sort((a, b) => parseDate(a).getTime() - parseDate(b).getTime());
    return sortedNextDates.length > 0 ? sortedNextDates[0] : undefined;
  };

  const findPreviousDate = (date: Date): string | undefined => {
    const previousDates = tldrDatesSorted.filter((tldrDate) => parseDate(tldrDate).getTime() < date.getTime());
    const sortedPreviousDates = previousDates.sort((a, b) => parseDate(b).getTime() - parseDate(a).getTime());
    return sortedPreviousDates.length > 0 ? sortedPreviousDates[0] : undefined;
  };

  const [nextDisabled, setNextDisabled] = useState<boolean>(findNextDate(date) ? false : true);
  const [previousDisabled, setPreviousDisabled] = useState<boolean>(findPreviousDate(date) ? false : true);

  const previousPage = () => {
    const previousDate = findPreviousDate(date);
    if (previousDate) {
      nagivate(generatePath(AppRoutesEnum.TLDR_DATE, { date: previousDate }));
      setNextDisabled(false);
    }
    setPreviousDisabled(previousDate && findPreviousDate(parseDate(previousDate)) ? false : true);
  };

  const nextPage = () => {
    const nextDate = findNextDate(date);
    if (nextDate) {
      nagivate(generatePath(AppRoutesEnum.TLDR_DATE, { date: nextDate }));
      setPreviousDisabled(false);
    }
    setNextDisabled(nextDate && findNextDate(parseDate(nextDate)) ? false : true);
  };

  const scrollToIdInCenter = useScrollToIdInCenter();

  const [startX, setStartX] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent<Element>) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent<Element>) => {
    if (startX === null) return;

    const endX = e.changedTouches[0].clientX;
    const deltaX = endX - startX;

    // Define the threshold for swipe action
    const swipeThreshold = 10;

    if (deltaX > swipeThreshold) {
      nextPage();
    } else if (deltaX < -swipeThreshold) {
      previousPage();
    }

    // Reset startX after handling swipe
    setStartX(null);
  };

  return (
    <Box
      sx={{
        mx: 'auto',
        zIndex: 9998,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'black'
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '5rem',
          top: '6px',
          width: { xs: 'calc(100% - 12px)', sm: 'calc(100% - 24px)', md: 'calc(100% - 36px)' },
          mx: 'auto',
          px: { xs: 1, sm: 2, md: 3 },
          zIndex: 9999,
          '::before': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            content: '""',
            borderRadius: 3,
            position: 'absolute',
            inset: '0',
            zIndex: -1
          }
        }}
      >
        <Button onClick={() => nagivate(AppRoutesEnum.HOME)} disableRipple sx={{ minWidth: 'fit-content' }}>
          <Stack direction={'row'} spacing={sm || xs ? 1 : 2} alignItems={'center'}>
            {xs || sm || md ? (
              <>
                {!xs && <NewspaperTwoTone sx={{ fontSize: '1.6rem' }} />}
                {/* <Badge
                  badgeContent={'NEW'}
                  color={'success'}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  slotProps={{ badge: { style: { transform: 'rotate(-25deg)', transformOrigin: '60px -40px' } } }}
                > */}
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 'bolder',
                    color: 'info.main',
                    textDecoration: 'none',
                    textWrap: 'wrap',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                  }}
                >
                  <Box component={'span'} sx={{ fontSize: '0.8rem' }}>
                    Ahmed's
                  </Box>
                  TLDR
                </Typography>
                {/* </Badge> */}
              </>
            ) : (
              <>
                {!xs && <NewspaperTwoTone sx={{ fontSize: '1.6rem' }} />}
                {/* <Badge
                  badgeContent={'NEW'}
                  color={'secondary'}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  slotProps={{ badge: { style: { transform: 'rotate(-25deg)', transformOrigin: '60px -40px' } } }}
                > */}
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 'bolder',
                    color: 'info.main',
                    textDecoration: 'none',
                    textWrap: 'nowrap',
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'center',
                    flexDirection: 'row'
                  }}
                >
                  Ahmed's TL;D
                  <Box component={'span'} sx={{ fontSize: '0.6rem' }}>
                    idn't have time to
                  </Box>
                  R
                </Typography>
                {/* </Badge> */}
              </>
            )}
          </Stack>
        </Button>
        {xs && page && (
          <Typography
            variant="caption"
            sx={{ fontStyle: 'italic', height: 0, width: 0, left: '40%', position: 'fixed', top: '3.8rem', opacity: 0.4 }}
          >
            ←swipe→
          </Typography>
        )}
        {categories && (
          <Box
            sx={{
              flexGrow: 1,
              height: '100%',
              mx: { xs: 0.5, sm: 3 },
              px: { xs: 1, sm: 3 },
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'nowrap',
              gap: 1,
              scrollbarWidth: 'thin'
            }}
          >
            {categories.map((category, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                className="navlink"
              >
                <Button onClick={() => scrollToIdInCenter(category)} disableRipple>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bolder',
                      textDecoration: 'none',
                      background:
                        category.toLowerCase() === 'belgie' || category.toLowerCase() === 'belgië'
                          ? 'linear-gradient(90deg, rgba(50, 50, 50, 1) 35%, rgba(255,224,0,1) 41%, rgba(255,224,0,1) 44%, rgba(255,0,0,1) 85%)'
                          : undefined,
                      backgroundClip: category.toLowerCase() === 'belgie' || category.toLowerCase() === 'belgië' ? 'text' : 'none',
                      color: category.toLowerCase() === 'belgie' || category.toLowerCase() === 'belgië' ? 'transparent' : 'primary.main',
                      p: { sm: 0, md: 1 },
                      m: { sm: 0, md: 1 }
                    }}
                  >
                    {sm || xs || md ? category.substring(0, 1) : category}
                  </Typography>
                </Button>
                {index !== categories.length - 1 && <FormatItalicTwoTone sx={{ fontSize: '1.4rem', color: 'info.main' }} />}
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            flexWrap: 'nowrap',
            gap: { sm: 0, md: 1 },
            justifyContent: 'center'
          }}
        >
          {!xs && (
            <Button sx={{ minWidth: 'fit-content' }} disabled={previousDisabled} onClick={previousPage}>
              <ArrowBackIosTwoTone></ArrowBackIosTwoTone>
            </Button>
          )}
          <Stack direction={'column'} alignItems={'center'}>
            <Button
              variant="outlined"
              id="long-button"
              aria-controls={openDates ? 'long-menu' : undefined}
              aria-expanded={openDates ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClickDates}
              onWheel={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.deltaY > 0 ? previousPage() : nextPage();
              }}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              sx={{ overscrollBehavior: 'contain', textWrap: 'nowrap' }}
            >
              {date.toLocaleDateString('en-BE', { day: 'numeric', month: 'long', year: 'numeric' }).toUpperCase() === 'INVALID DATE'
                ? 'Select date'
                : date.toLocaleDateString('en-BE', { day: 'numeric', month: `${sm || xs ? 'numeric' : 'long'}`, year: 'numeric' })}
            </Button>
            {xs && page && (
              <Typography variant="caption" sx={{ fontStyle: 'italic', height: 0, opacity: 0.4 }}>
                ←swipe→
              </Typography>
            )}
          </Stack>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorElDates}
            open={openDates}
            onClose={handleCloseDates}
            slotProps={{
              paper: {
                style: {
                  maxHeight: 500,
                  marginTop: '2rem',
                  backgroundColor: grey[900]
                }
              }
            }}
          >
            {tldrDatesSorted.map((tldrDate, index) => (
              <MenuItem
                key={index}
                selected={tldrDate === dateFromPath}
                onClick={() => {
                  setNextDisabled(findNextDate(parseDate(tldrDate)) ? false : true);
                  setPreviousDisabled(findPreviousDate(parseDate(tldrDate)) ? false : true);
                  nagivate(generatePath(AppRoutesEnum.TLDR_DATE, { date: tldrDate }));
                  handleCloseDates();
                }}
              >
                <Typography color="info.light" variant="body1">
                  {parseDate(tldrDate).toLocaleDateString('en-BE', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
          {!xs && (
            <Button sx={{ minWidth: 'fit-content' }} disabled={nextDisabled} onClick={nextPage}>
              <ArrowForwardIosTwoTone></ArrowForwardIosTwoTone>
            </Button>
          )}

          {user ? (
            <Box>
              <Button onClick={handleClickMenu}>
                <Stack direction={'row'} gap={1.5} alignContent={'center'} justifyContent={'center'} alignItems={'center'}>
                  <Box component={'img'} src={user.photoURL ?? '/images/profile.jpg'} sx={{ borderRadius: '20%', height: '2rem' }}></Box>
                  {(lg || xl) && (
                    <Typography variant="h6" sx={{ fontSize: '1.1rem', textWrap: 'nowrap', textOverflow: 'ellipsis' }} color={'info.main'}>
                      {user?.displayName}
                    </Typography>
                  )}
                </Stack>
              </Button>
              <Menu
                anchorEl={anchorElMenu}
                id="account-menu"
                open={openMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                slotProps={{
                  paper: {
                    sx: {
                      marginTop: '2rem',
                      backgroundColor: grey[900],
                      color: grey[50],
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                      }
                    }
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  onClick={() => {
                    toast.info('Coming soon!', { position: 'top-right', autoClose: 1000, theme: 'dark', closeOnClick: true, pauseOnHover: false });
                    handleCloseMenu();
                  }}
                >
                  <ListItemIcon>
                    <Avatar />
                  </ListItemIcon>
                  <Typography variant="button">Account</Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    toast.info('Coming soon!', { position: 'top-right', autoClose: 1000, theme: 'dark', closeOnClick: true, pauseOnHover: false });
                    handleCloseMenu();
                  }}
                >
                  <ListItemIcon>
                    <Settings sx={{ color: 'white' }} fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="button">Settings</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    signOut(auth);
                    toast.success('Logged out', { position: 'top-right', autoClose: 500, theme: 'dark', closeOnClick: true, pauseOnHover: false });
                  }}
                >
                  <ListItemIcon>
                    <Logout sx={{ color: 'white' }} fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="button">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : loading ? (
            <Loader height={'2.8rem'} />
          ) : (
            <Button
              sx={{ mx: { md: 1, lg: 2 }, px: { md: 0, lg: 2 } }}
              onClick={() => {
                signInWithPopup(auth, provider).then((result) => {
                  if (!result.user) {
                    toast.error('Login error, please try again.', { theme: 'dark' });
                  }
                });
              }}
              variant="outlined"
            >
              <LoginTwoTone />
              {(lg || xl) && (
                <Typography sx={{ ml: 1 }} variant="button">
                  Login
                </Typography>
              )}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
