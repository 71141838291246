import { Box, Link, Stack, Typography } from '@mui/material';
import { TldrArticle } from '../data/articles';
import { useEffect } from 'react';

export type ArticleProps = {
  article: TldrArticle;
};
export const Article = (props: ArticleProps): JSX.Element => {
  const { id, title, text, images, videos, externalArticleURL, externalArticleURLTitle, date } = props.article;

  return (
    <Box
      id={id}
      sx={{
        p: 3,
        borderRadius: 3,
        background: 'rgba(0, 0, 0, 0.6)',
        filter: 'drop-shadow(0 0 1em rgba(0, 0, 0, 0.2))'
      }}
    >
      <Box sx={{ px: { sm: 1, md: 2 } }}>

        {date && (
          <Box sx={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-start', mb:0}}>
            <Typography variant="caption" sx={{ textWrap: 'nowrap'}}>
              {date}
            </Typography>
          </Box>
          )}
        <Typography variant="h5" sx={{mb:2}}>
          {title}
        </Typography>
        {Array.isArray(text) ? (
          text.map((t, index) => (
            <Typography
              sx={{ mb: index === text.length - 1 && !externalArticleURL ? 4 : 2, textAlign: 'justify', textAlignLast: 'start' }}
              key={index}
            >
              {t}
            </Typography>
          ))
        ) : (
          <Typography sx={{ mb: externalArticleURL ? 2 : 4, textAlign: 'justify', textAlignLast: 'start' }}>{text}</Typography>
        )}
      </Box>
      {externalArticleURL && (
        <Box sx={{ mb: 4 }}>
          <Link href={externalArticleURL} target="blank" sx={{ px: { sm: 1, md: 2 } }}>
            <Typography variant="button" color="primary">
              Link: {externalArticleURLTitle ?? 'Link naar artikel/product'}
            </Typography>
          </Link>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          flexDirection: { sm: 'column', md: 'row' },
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        {images?.map((image, index) => (
          <Stack key={index} direction={'column'} spacing={1}>
            <Box
              component={'img'}
              src={image.url}
              sx={{
                objectFit: 'contain',
                maxWidth: '1000px',
                maxHeight: '700px',
                width: { xs: '85vw', sm: '80vw', md: '70vw', lg: '60vw', xl: '50vw' },
                borderRadius: 2
              }}
            />
            <Typography variant="caption">{image.caption}</Typography>
          </Stack>
        ))}
        {videos?.map((video, index) => (
          <Box key={index} sx={{}}>
            {video.embedAsYoutube ? (
              <Stack key={index} direction={'column'} spacing={1}>
                <Box
                  component={'iframe'}
                  className={'rounded-4'}
                  sx={{
                    border: 'none',
                    borderRadius: 2,
                    width: { xs: '85vw', sm: '80vw', md: '70vw', lg: '60vw', xl: '50vw' },
                    //height set to always have 16:9 aspect ratio
                    height: {
                      xs: 'calc(85vw * 9 / 16)',
                      sm: 'calc(80vw * 9 / 16)',
                      md: 'calc(70vw * 9 / 16)',
                      lg: 'calc(60vw * 9 / 16)',
                      xl: 'calc(50vw * 9 / 16)'
                    },
                    maxWidth: '1000px'
                  }}
                  referrerPolicy="strict-origin-when-cross-origin"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  src={`${video.url}?start=${video.startTime}&end=${video.endTime}`}
                  title={video.caption}
                  allowFullScreen
                ></Box>
                <Typography variant="caption">{video.caption}</Typography>
              </Stack>
            ) : (
              <Stack key={index} direction={'column'} spacing={1}>
                <Box
                  component={'video'}
                  src={video.url}
                  autoPlay={video.autplay}
                  controls
                  muted
                  sx={{
                    objectFit: 'contain',
                    maxWidth: '1000px',
                    maxHeight: '700px',
                    width: { xs: '85vw', sm: '80vw', md: '70vw', lg: '60vw', xl: '50vw' },
                    borderRadius: 2
                  }}
                ></Box>
                <Typography variant="caption">{video.caption}</Typography>
              </Stack>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
