import { Button, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

export type CategoryHeaderProps = {
  title: string;
};

export const CategoryHeader = ({ title }: CategoryHeaderProps): JSX.Element => {
  const isBelgium = title.toLowerCase() === 'belgie' || title.toLowerCase() === 'belgië';
  return (
    <Button
      disableRipple
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
        mb: 2,
        py: 1.6,
        px: 3,
        background: 'rgba(0, 0, 0, 0.5)',
        filter: 'drop-shadow(0 0 1.6em rgba(0, 0, 0, 0.3))'
      }}
    >
      <Typography
        variant="h4"
        sx={{
          background: isBelgium
            ? 'linear-gradient(90deg, rgba(50, 50, 50, 1) 25%, rgba(255,224,0,1) 45%, rgba(255,224,0,1) 55%, rgba(255,0,0,1) 85%)'
            : undefined,
          backgroundClip: isBelgium ? 'text' : 'none',
          color: isBelgium ? 'transparent' : red[600]
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </Button>
  );
};
