import { useMediaQuery, useTheme } from '@mui/material';

interface ScreenSizes {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

export const useResponsiveScreen = (): ScreenSizes => {
  const theme = useTheme();

  const screenSizes: ScreenSizes = {
    xs: useMediaQuery(theme.breakpoints.between('xs', 'sm')),
    sm: useMediaQuery(theme.breakpoints.between('sm', 'md')),
    md: useMediaQuery(theme.breakpoints.between('md', 'lg')),
    lg: useMediaQuery(theme.breakpoints.between('lg', 'xl')),
    xl: useMediaQuery(theme.breakpoints.up('xl'))
  };

  return screenSizes;
};
