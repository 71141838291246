import { ToastContainer } from 'react-toastify';
import './App.css';
import { Routing } from './Routing';
import { ParallaxProvider } from 'react-scroll-parallax';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ParallaxProvider>
      <Routing />
      <ToastContainer />
    </ParallaxProvider>
  );
}

export default App;
