import { Alert, Box, Button, Stack } from '@mui/material';
import { TldrPages } from '../data/articles';
import { Article } from '../components/Article';
import { CategoryHeader } from '../components/CategoryHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIosNewTwoTone } from '@mui/icons-material';
import { useParseDate } from '../util/hooks/useParseDate';

export const HomePage = () => {
  const { date } = useParams();
  const navigate = useNavigate();
  const parseDate = useParseDate();

  const latestDate = TldrPages.map((page) => page.date).sort((a, b) => parseDate(b).getTime() - parseDate(a).getTime())[0];
  const page = date
    ? TldrPages.find((page) => parseDate(page.date).getTime() === parseDate(date).getTime())
    : TldrPages.find((page) => page.date === latestDate);

  const categories = page?.articles
    ?.map((article) => article.category)
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index);
  return (
    <>
      {page && categories && categories.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'nowrap', pb: 30 }}>
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'column', gap: 2 }}>
            {categories.map((category) => (
              <Box key={category} id={category} className="category">
                <CategoryHeader title={category}></CategoryHeader>
                <Stack spacing={2}>
                  {page.articles
                    ?.filter((article) => article.category.toLowerCase() === category.toLowerCase())
                    .map((article, index) => <Article key={index} article={article}></Article>)}
                </Stack>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, alignContent: 'center', alignItems: 'center', p: 10 }}>
          <Alert severity="info">No articles found for this date</Alert>
          <Button variant="outlined" startIcon={<ArrowBackIosNewTwoTone />} onClick={() => navigate(-1)} sx={{ px: 5, py: 1.5 }}>
            Back
          </Button>
        </Box>
      )}
    </>
  );
};
