import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIosTwoTone, HomeTwoTone } from '@mui/icons-material';
import { AppRoutesEnum } from '../util/enum/AppRoutesEnum';
export const ErrorPage = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        width: '80vw',
        height: '50vh'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          borderRadius: '1rem',
          backgroundColor: 'dark',
          height: 'fit-content',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          '::before': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            content: '""',
            borderRadius: 3,
            position: 'absolute',
            inset: '100px 10px 0px 10px',
            zIndex: -1
          }
        }}
      >
        <Typography sx={{ fontSize: '2.4rem' }}>This page does not exist</Typography>
        <Stack direction={'row'} spacing={3}>
          <Button onClick={() => navigate(-2)} variant="contained" color="primary" sx={{ px: 4, py: 2, borderRadius: '1rem', fontWeight: 'bold' }}>
            <ArrowBackIosTwoTone sx={{ pr: 2 }}></ArrowBackIosTwoTone> Go back
          </Button>
          <Button
            onClick={() => navigate(AppRoutesEnum.HOME)}
            variant="contained"
            color="success"
            sx={{ px: 4, py: 2, borderRadius: '1rem', fontWeight: 'bold' }}
          >
            <HomeTwoTone sx={{ pr: 2 }}></HomeTwoTone> Return to homepage
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
